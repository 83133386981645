<template>
  <div id="cookie-banner" v-if="coockieBannerShouldBeShown">
    <div id="cookie-banner-content">
      <h1>Datenschutz Präferenz</h1>
      <p style="font-size: 0.825rem">
        Wir benötigen Ihre Einwilligung, bevor Sie unsere Website weiter
        besuchen können. Wir verwenden Cookies und andere Technologien auf
        unserer Website. Einige von ihnen sind essenziell, während andere uns
        helfen, diese Website und Ihre Erfahrung zu verbessern. Personenbezogene
        Daten können verarbeitet werden (z. B. IP-Adressen), z. B. für
        personalisierte Anzeigen und Inhalte oder die Messung von Anzeigen und
        Inhalten. Weitere Informationen über die Verwendung Ihrer Daten finden
        Sie in unserer
        <a href="https://dsag.de/datenschutzerklaerung/" target="_blank"
          >Datenschutzerklärung</a
        >. Es besteht keine Verpflichtung, in die Verarbeitung Ihrer Daten
        einzuwilligen, um dieses Angebot zu nutzen. Sie können Ihre Auswahl
        jederzeit unter Einstellungen widerrufen oder anpassen. Bitte beachten
        Sie, dass aufgrund individueller Einstellungen möglicherweise nicht alle
        Funktionen der Website verfügbar sind.
      </p>
      <hr />
      <p style="font-size: 0.75rem">
        Einige Services verarbeiten personenbezogene Daten in den USA. Mit Ihrer
        Einwilligung zur Nutzung dieser Services willigen Sie auch in die
        Verarbeitung Ihrer Daten in den USA gemäß Art. 49 (1) lit. a GDPR ein.
        Der EuGH stuft die USA als ein Land mit unzureichendem Datenschutz nach
        EU-Standards ein. Es besteht beispielsweise die Gefahr, dass US-Behörden
        personenbezogene Daten in Überwachungsprogrammen verarbeiten, ohne dass
        für Europäerinnen und Europäer eine Klagemöglichkeit besteht.
      </p>
      <hr />
      <b-form>
        <b-card no-body class="mb-3">
          <b-card-header>
            <b-row>
              <b-col cols="10">
                <b-form-checkbox :checked="true" disabled switch
                  >Essentiell</b-form-checkbox
                >
              </b-col>
              <b-col cols="2" class="text-right">
                <b-button
                  variant="clean"
                  title="Informationen anzeigen"
                  :aria-expanded="essentialVisible ? 'true' : 'false'"
                  aria-controls="essential-collapse"
                  @click="essentialVisible = !essentialVisible"
                >
                  <i class="fal fa-chevron-up"></i>
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>
          <b-collapse v-model="essentialVisible" id="essential-collapse">
            <b-card-body>
              <p style="font-size: 0.825rem">
                Essenzielle Services ermöglichen grundlegende Funktionen und
                sind für das ordnungsgemäße Funktionieren der Website
                erforderlich.
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-3">
          <b-card-header>
            <b-row>
              <b-col cols="10">
                <b-form-checkbox
                  :value="true"
                  :unchecked-value="false"
                  v-model="form.googleAnalytics"
                  switch
                  >Statistik</b-form-checkbox
                >
              </b-col>
              <b-col cols="2" class="text-right">
                <b-button
                  variant="clean"
                  title="Informationen anzeigen"
                  :aria-expanded="googleTagsVisible ? 'true' : 'false'"
                  aria-controls="google-tags-collapse"
                  @click="googleTagsVisible = !googleTagsVisible"
                >
                  <i class="fal fa-chevron-up"></i>
                </b-button>
              </b-col>
            </b-row>
          </b-card-header>
          <b-collapse v-model="googleTagsVisible" id="google-tags-collapse">
            <b-card-body>
              <p style="font-size: 0.825rem">
                Statistik-Cookies sammeln Nutzungsdaten, die uns Aufschluss
                darüber geben, wie unsere Besucher mit unserer Website umgehen.
              </p>
              <table>
                <thead>
                  <tr>
                    <th colspan="2">Google Analytics</th>
                  </tr>
                </thead>
                <tbody style="font-size: 0.825rem">
                  <tr>
                    <th>Beschreibung</th>
                    <td>
                      Google Analytics ist ein Webanalysedienst von Google, der
                      unseren Website-Verkehr nachverfolgt und analysiert. Er
                      ermöglicht Einblicke in das Nutzerverhalten auf unserer
                      Website und unterstützt uns dabei, unsere Zielgruppe
                      besser zu verstehen sowie unsere Online-Aktivitäten zu
                      optimieren.
                    </td>
                  </tr>
                  <tr>
                    <th>Hosts</th>
                    <td>www.googletagmanager.com, www.google-analytics.com</td>
                  </tr>
                  <tr>
                    <th colspan="2" style="font-size: 0.9rem">
                      Provider-Informationen
                    </th>
                  </tr>
                  <tr>
                    <th>Provider-Name</th>
                    <td>Google</td>
                  </tr>
                  <tr>
                    <th>Beschreibung</th>
                    <td>
                      Google LLC, die Dachgesellschaft aller Google-Dienste, ist
                      ein Technologieunternehmen, das verschiedene
                      Dienstleistungen anbietet und sich mit der Entwicklung von
                      Hardware und Software beschäftigt.
                    </td>
                  </tr>
                  <tr>
                    <th>Adresse</th>
                    <td>Gordon House, Barrow Street, Dublin 4, Ireland</td>
                  </tr>
                  <tr>
                    <th>URL der Datenschutzerklärung</th>
                    <td>
                      <a href="https://business.safety.google/privacy/?hl=de"
                        >https://business.safety.google/privacy/?hl=de</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <th>Cookie-URL</th>
                    <td>
                      <a
                        href="https://policies.google.com/technologies/cookies?hl=de"
                        >https://policies.google.com/technologies/cookies?hl=de</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-row>
          <b-col cols="6">
            <b-button @click="acceptSelectedCookies"
              >Auswahl speichern</b-button
            >
          </b-col>
          <b-col cols="6" class="text-right">
            <b-button @click="acceptAllCookies" variant="primary"
              >Alle akzeptieren</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>
<script>
function acceptGoogleTags() {
  window.gtag("consent", "update", {
    analytics_storage: "granted",
    google_analytics: "granted",
  });
}
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
export default {
  data() {
    return {
      form: {
        essential: true,
        googleAnalytics: false,
      },
      googleTagsVisible: false,
      essentialVisible: false,
    };
  },
  methods: {
    acceptSelectedCookies() {
      setCookie(
        "cookieConsent",
        JSON.stringify({
          consent: this.form,
          date: this.$formatDateTime(new Date()),
        }),
        90
      );
      window.location.reload();
    },
    acceptNeccessaryCookies() {
      this.form.googleAnalytics = false;
      this.acceptSelectedCookies();
    },
    acceptAllCookies() {
      this.form.googleAnalytics = true;
      this.acceptSelectedCookies();
    },
  },
  computed: {
    coockieBannerShouldBeShown() {
      const cookieConsent = getCookie("cookieConsent");
      if (!cookieConsent) return true;
      let cookieValue = JSON.parse(cookieConsent);
      if (cookieValue.consent.googleAnalytics) {
        acceptGoogleTags();
      }
      //In the future we can check for the date here
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
#cookie-banner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  backdrop-filter: blur(1rem) brightness(60%);
}
#cookie-banner-content {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 200px;
  background-color: white;
  padding: 1.5rem 3rem;
}
</style>
